.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 14px 0px;

    &__logo {
        background: url('../../assets/images/Logo.png') center no-repeat;
        background-size: cover;
        width: 274px;
        height: 90px;

        @media (max-width:$bp2) {
            width: 173px;
            height: 57px;
        }

        @media (max-width:$bp1) {
            width: 46vw;
            height: 15.2vw;
        }

    }

    &__btns {
        @include center-align;
        gap: 22px;

        @media (max-width:$bp1) {
            gap: 15px;
        }
    }
}