h1 {
    font-family: "KabelCTT";
    font-weight: 700;
    font-size: 60px;

    @media (max-width:$bp3) {
        font-size: 44px;
    }

    @media (max-width:$bp1) {
        font-size: 11vw;
    }
}

h2 {
    font-family: "KabelCTT";
    font-weight: 700;
    font-size: 24px;
    color: $section-titles;
}


.kabel-reg-24 {
    font-size: 24px;

    @media (max-width:$bp1) {
        font-size: 6.4vw;
    }
}