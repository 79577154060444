@font-face {
    font-family: 'Kabel';
    src: url('../../assets/fonts/kabel/Kabel.eot');
    src: url('../../assets/fonts/kabel/Kabel.eot?#iefix') format('embedded-opentype'),
        url('../../assets/fonts/kabel/Kabel.woff2') format('woff2'),
        url('../../assets/fonts/kabel/Kabel.woff') format('woff'),
        url('../../assets/fonts/kabel/Kabel.ttf') format('truetype'),
        url('../../assets/fonts/kabel/Kabel.svg#Kabel') format('svg');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'KabelCTT';
    src: url('../../assets/fonts/kabelCTT_Medium/KabelCTT-Medium.eot');
    src: url('../../assets/fonts/kabelCTT_Medium/KabelCTT-Medium.eot?#iefix') format('embedded-opentype'),
        url('../../assets/fonts/kabelCTT_Medium/KabelCTT-Medium.woff2') format('woff2'),
        url('../../assets/fonts/kabelCTT_Medium/KabelCTT-Medium.woff') format('woff'),
        url('../../assets/fonts/kabelCTT_Medium/KabelCTT-Medium.ttf') format('truetype'),
        url('../../assets/fonts/kabelCTT_Medium/KabelCTT-Medium.svg#KabelCTT-Medium') format('svg');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'KabelCTT';
    src: url('../../assets/fonts/kabelCTT_Medium/KabelCTT-MediumBold.eot');
    src: url('../../assets/fonts/kabelCTT_Medium/KabelCTT-MediumBold.eot?#iefix') format('embedded-opentype'),
        url('../../assets/fonts/kabelCTT_Medium/KabelCTT-MediumBold.woff2') format('woff2'),
        url('../../assets/fonts/kabelCTT_Medium/KabelCTT-MediumBold.woff') format('woff'),
        url('../../assets/fonts/kabelCTT_Medium/KabelCTT-MediumBold.ttf') format('truetype'),
        url('../../assets/fonts/kabelCTT_Medium/KabelCTT-MediumBold.svg#KabelCTT-MediumBold') format('svg');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Monopoly Inline';
    src: url('../../assets/fonts/monopoly_inline/Monopoly-Inline.eot');
    src: url('../../assets/fonts/monopoly_inline/Monopoly-Inline.eot?#iefix') format('embedded-opentype'),
        url('../../assets/fonts/monopoly_inline/Monopoly-Inline.woff2') format('woff2'),
        url('../../assets/fonts/monopoly_inline/Monopoly-Inline.woff') format('woff'),
        url('../../assets/fonts/monopoly_inline/Monopoly-Inline.ttf') format('truetype'),
        url('../../assets/fonts/monopoly_inline/Monopoly-Inline.svg#Monopoly-Inline') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}