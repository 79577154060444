.lang-changer {
    position: relative;

    &__choosed {
        background-color: $second-bg;
        border-radius: 25px;
        position: relative;
        z-index: 10;
        width: 51px;
        height: 40px;
        @include center-align;
        cursor: pointer;

        &.active {
            background-color: $light-blue;
        }
    }

    &__options {
        max-height: 0px;
        overflow: hidden;
        transition: all linear 0.1s;
        position: absolute;
        padding-top: 40px;
        z-index: 2;
        top: 0;
        background-color: $second-bg;
        width: 100%;
        border-radius: 25px;
        @include center-align;
        flex-direction: column;

        &.active {
            max-height: 150px;
        }
    }

    &__option {
        padding: 5px 0px;
        margin-bottom: 5px;
        cursor: pointer;
    }
}

.button {
    background-color: $second-bg;
    @include center-align;
    cursor: pointer;

    &__wrap {
        @include center-align;
    }

    &:hover {
        box-shadow: 0px 0px 10px #62DED7;
    }
}

.wallet-btn {
    height: 40px;
    border-radius: 25px;
    width: 187px;

    @include center-align;
    gap: 11px;

    @media (max-width:$bp2) {
        width: fit-content;
        padding: 0 18px;
    }

    &__text {
        @media (max-width:$bp2) {
            display: none;
        }
    }
}

.button-big {
    @include center-align;
    width: 316px;
    height: 62px;
    font-family: "KabelCTT";
    font-size: 20px;
    font-weight: 700;
    border-radius: 100px;

    &--blue {
        background: linear-gradient(109.17deg, #5CBFCD -14.04%, #518FD5 32.45%, #545FDE 73.47%);
    }

    &--grey {
        border: 1px solid #5779DD;
    }
}

.button-small {
    @include center-align;
    width: 180px;
    height: 39px;
    background: linear-gradient(138.47deg, #62DED7 6.73%, #3AA8E2 39.8%, #5779DD 76.51%);
    border-radius: 25px;
    font-family: "KabelCTT";
    font-size: 15px;
    font-weight: 700;

    &--grey {
        width: 100%;
        background: $second-bg;
        font-family: "Kabel";
        position: relative;
        font-weight: 400;
        color: #B8C8F2;
        position: relative;

        &::after {
            content: "";
            width: calc(100% + 2px);
            height: calc(100% + 2px);
            position: absolute;
            top: -1px;
            z-index: -1;
            border-radius: 25px;

            background: linear-gradient(138.47deg, #62DED7 6.73%, #3AA8E2 39.8%, #5779DD 76.51%);
        }



    }
}

.shadow {
    box-shadow: 0px 0px 10px #62DED7;

    &:hover {
        box-shadow: none;
    }
}