.footer {




    &__wrapper {
        font-size: 12px;
        font-family: "KabelCTT";
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        height: 235px;
        padding-bottom: 45px;

        @media (max-width:$bp3) {
            flex-direction: column-reverse;
            align-items: flex-start;
            height: 265px;
            justify-content: flex-start;
            gap: 22px;
        }
    }

    &__logo {
        background: url('../../assets/images/footer_logo.png') no-repeat center;
        width: 158px;
        height: 31px;
        margin: 0 auto;
    }

    &__middle {
        @media (max-width:$bp3) {
            order: 3;
            width: 100%;
            margin-bottom: 45px;
        }
    }

    &__right {
        display: flex;
        gap: 22px;
    }

    &__links {
        @include center-align;
        gap: 29px;
        margin-top: 35px;

        @media (max-width:$bp3) {
            margin-top: 20px;
        }
    }
}