html,
body,
div,
span,
h1,
h2,
h3,
h4,
h5,
h6,
p,
em,
img,
strong,
sub,
sup,
b,
u,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
table,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    vertical-align: baseline;
    background: transparent;
}

* {
    text-decoration: none;
    list-style: none;
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

html {
    font-family: 'Kabel';
    background-color: $main-bg;
    height: 100%;
    color: $main-font;
}

body {
    height: 100%;
}

.App {
    overflow: hidden;
}

a {
    color: inherit;
}

.container {
    max-width: 1280px;
    padding: 0px 15px !important;
    margin: 0 auto;

}

.app-layout {
    position: relative;
}