.actions {
    &__header {
        display: flex;
        margin-bottom: 11px;
        color: rgba(214, 232, 210, 1);
        font-family: "KabelCTT";
        font-weight: 700;
        padding-left: 24px;

        @media (max-width:$bp3) {
            display: none;
        }
    }

    & .col1 {
        flex: 0 1 34%;

    }

    & .col2 {
        flex: 0 1 41%;
    }

    &__btn {
        @include center-align;
        margin-top: 36px;
    }
}


.action {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 22px 0px;
    background: linear-gradient(90.53deg, #5CBFCC -6.08%, #5992D6 46.97%, #555FDE 97.86%);
    border-radius: 19px;
    margin-bottom: 11px;
    padding-left: 24px;
    position: relative;
    transition: all 0.3s ease;

    @media (max-width:$bp3) {
        flex-direction: column;
        align-items: flex-start;
        padding: 10px 20px 14px 60px;
        gap: 18px;
        max-height: 62px;
        overflow: hidden;
        cursor: pointer;
    }

    &.open {
        max-height: 500px;

        & .action__chevron {
            svg {
                transform: rotate(180deg);
            }

        }
    }

    &__label {
        display: none;

        @media (max-width:$bp3) {
            display: block;
        }
    }

    &__chevron {
        display: none;
        position: absolute;
        right: 14px;
        top: 30px;
        transform: translateX(-50%) translateY(-50%);

        @media (max-width:$bp3) {
            display: block;
        }
    }

    &__col {
        display: flex;
        align-items: center;
        padding-right: 20px;

        @media (max-width:$bp3) {
            flex-direction: column;
            gap: 5px;
            align-items: flex-start;
        }
    }

    &__image {

        margin-right: 20px;

        @media (max-width:$bp3) {
            position: absolute;
            left: 17px;
            top: 17px;
        }
    }



    &__amount {
        & span {
            color: #62DED7;
        }

        &.red {
            span {
                color: #FFADAD;
            }
        }
    }
}