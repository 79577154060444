.answer-section {}

.answer {
    overflow: hidden;

    &__header {
        margin-bottom: 0px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 80px;
        padding-left: 18px;
        border-bottom: 1px solid #5CBFCC;
        cursor: pointer;

        svg {
            width: 19px;
            height: 19px;
            margin-left: 15px;
            flex-shrink: 0;
        }
    }

    &__title {
        font-family: "KabelCTT";
        font-size: 20px;
        font-weight: 700;

        @media (max-width:$bp2) {
            font-size: 16px;
        }
    }

    &__content {
        padding: 0px 30px 0px 30px;
        max-height: 0px;

        @media (max-width:$bp2) {
            padding: 0px 15px;
            font-size: 14px;
        }
    }

    &.open {
        .answer__content {
            max-height: 1000px;
        }

        .answer__header {
            margin-bottom: 20px;

            svg {
                transition: all 0.1s linear;
                transform: rotate(45deg);
            }
        }
    }
}