@mixin center-align {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin bp-hide($bp) {
    display: none;

    @media (max-width:$bp) {
        display: flex;
    }
}