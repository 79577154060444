.account {
    &__banner {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding-top: 30px !important;

        @media (max-width:$bp4) {
            flex-direction: column-reverse;
            gap: 105px;
        }

        &>img {
            position: absolute;
            top: 0;
            z-index: -10;

            @media(max-width:$bp3) {
                right: -940px;
            }
        }
    }

    &__info {
        margin-top: 115px;
        margin-bottom: 105px;
        position: relative;

        @media(max-width:$bp2) {
            margin-top: 90px;
        }

        &>img {
            position: absolute;
            right: 30%;
            top: auto;
            bottom: -90px;
            z-index: -1;
            display: block !important;
        }
    }

    &__cards {
        margin-top: 75px;

        @media(max-width:$bp4) {
            margin-top: 90px;
        }
    }

    &__instruction {
        margin-bottom: 72px;
    }

    &__answers {
        background: $second-bg;
        padding-bottom: 80px;
        padding-top: 40px;
        margin-top: 145px;
        position: relative;

        &>img {
            position: absolute;
            top: -195px;
            display: none;
            right: 20%;

            @media (max-width:$bp3) {
                display: block;
                right: auto;
                left: -35px;
                top: -90px;
            }
        }
    }

    &__actions {
        position: relative;

        &>img {
            left: 0px;
            position: absolute;
            bottom: -500px;
            z-index: -10;

            @media (max-width:$bp3) {
                display: none;
            }
        }
    }

    &__numbers {
        margin-top: 20px;
        margin-bottom: 120px;

        @media (max-width:$bp2) {
            margin-bottom: 80px;
        }
    }
}

.account-cards {
    &__title {
        margin-bottom: 15px;
    }

    &__text {
        margin-bottom: 45px;

        @media (max-width: $bp1) {
            font-size: 4vw;
            line-height: 5.8vw;
        }
    }

    &__cards {
        display: flex;
        flex-wrap: wrap;
        gap: 18px;

        @media (max-width:$bp4) {
            display: none;
        }
    }

    &__slider {
        display: none;

        @media (max-width:$bp4) {
            display: block;
        }
    }
}

.banner {
    &__link {
        background-color: rgba(35, 37, 47, 0.44);
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 11px;
        height: 38px;
        margin-top: 13px;
        border-radius: 19px;
        cursor: pointer;
    }

    &__left {
        flex-grow: 1;
        margin-right: 30px;

        @media (max-width:$bp4) {
            margin-right: 0px;
        }
    }

    &__right {
        flex-shrink: 0;
    }
}

.account-numbers {
    position: relative;

    &__address {
        display: flex;
        color: $light-blue;
        align-items: center;
        margin-bottom: 25px;
        font-weight: 700;
        flex-wrap: wrap;

        &>div {
            display: flex;
            align-items: center;
            flex-shrink: 0;
            margin-right: 2px;
        }

        & svg {
            width: 18px;
            flex-shrink: 0;
            height: 18px;
            margin-right: 10px;
        }

        @media (max-width:$bp2) {
            font-size: 3vw;
            justify-content: center;
        }
    }

    &__flex {
        display: flex;
        justify-content: space-between;

        @media (max-width:$bp4) {
            flex-direction: column;
            gap: 12px;
        }
    }

    &__card {
        display: flex;
        flex-shrink: 0;
        flex-basis: 34%;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background-color: $light-blue;
        border-radius: 19px;
        height: 250px;

        @media (max-width:$bp4) {
            height: fit-content;
            padding: 45px 10px;
        }

        &:nth-child(2) {
            width: 34%;
            bottom: -20px;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            background: rgba(35, 37, 47, 0.44);
            border: 1px solid #5CB9CE;
            position: absolute;

            &::after {
                content: "";
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                background: url("../../assets/images/account-bg/account_bg_dec_1.png") no-repeat;
                z-index: -2;
                background-size: contain;
                -webkit-filter: blur(4px);
                -moz-filter: blur(4px);
                -o-filter: blur(4px);
                -ms-filter: blur(4px);
                filter: blur(4px);

                @media (max-width:$bp4) {
                    background-position: 10% 20%;
                }
            }

            @media (max-width:$bp4) {
                position: relative;
                left: 0;
                bottom: 0;
                transform: none;
                width: 100%;
            }

            @media (min-width:$bp4) {
                & .account-numbers__title {
                    font-size: 100px;
                }

                & .account-numbers__subtitle {
                    font-size: 34px;
                }
            }

        }

        &:nth-child(3) {
            background: #5463DD;
        }
    }

    &__title {
        font-family: 'Monopoly Inline';
        font-size: 72px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        @media (max-width:$bp6) {
            font-size: 55px;
        }

        @media (max-width:$bp4) {
            font-size: 59px;
        }

        @media (max-width:$bp1) {
            font-size: 15vw;
        }
    }

    &__subtitle {
        font-size: 22px;
        text-align: end;
        max-width: 270px;
        font-family: "Kabel";

        @media (max-width:$bp4) {
            font-size: 20px;
        }

        @media (max-width:$bp1) {
            font-size: 5.3vw;
        }
    }
}