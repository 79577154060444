$main-bg: #1D1E22;
$second-bg: #23252F;
$light-blue: #57AED0;
$dark-blue: #5463DD;

$main-font: #FFFFFF;
$section-titles: #6F93FE;

$bp1: 375px;
$bp2: 576px;
$bp3: 768px;
$bp4: 992px;
$bp5: 1200px;
$bp6: 1440px;